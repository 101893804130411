@import '../styles/global';

.key-enter {
  @include mix-pop-enter;
}

.key-enter-active {
  @include mix-pop-enter-active;
}

.key-enter-done {
  @include mix-pop-enter-done;
}

$key-gap: 5px;

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $key-gap;

  .keyboard__row {
    display: flex;
    gap: $key-gap;

    $key-width: 40px;

    .row__key {
      @include mix-button;
      @include mix-focus-background(var-dark('color-quantenary'));
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: $font-size-sm;
      font-family: $font-family-primary;
      text-transform: capitalize;
      height: $key-width;
      width: 32px;
      color: var-dark('color-primary');
      background: var-dark('color-tertiary');
      border-radius: $border-radius * 0.5;
      transition: transform $transition-duration * 2 $transition-timing;

      &.large {
        width: ($key-width - $key-gap) * 1.5;
        font-size: $font-size-sm - 0.075rem;

        svg {
          height: $key-width * 0.5;
        }
      }

      &.wrong {
        background: var-dark('background-tertiary');

        &:focus,
        &:hover {
          background: var-dark('background-quantenary');
        }
      }

      &.partial {
        background: var(--cyan-primary);

        &:focus,
        &:hover {
          background: var(--cyan-secondary);
        }
      }

      &.correct {
        background: var(--green-primary);

        &:focus,
        &:hover {
          background: var(--green-secondary);
        }
      }
    }
  }
}
