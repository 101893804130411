// breakpoints (to use with min-width as per mobile-first responsive design)
$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// transitions
$transition-duration: 150ms;
$transition-timing: ease-in-out;

// gap spacings
$gap-xs: 4px;
$gap-sm: 8px;
$gap-md: 12px;
$gap-lg: 16px;
$gap-xl: 32px;

// other
$border-radius: $gap-sm;

// components
$sidebar-width: 220px;
