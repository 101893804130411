@import 'src/styles/global';

.sidebar {
  position: fixed;
  top: 0;
  left: -$sidebar-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--background-secondary);
  width: $sidebar-width;
  padding: $gap-lg * 1.5 $gap-lg;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  transition: left $transition-timing $transition-duration;

  .sidebar__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-sm;

    .sidebar__title {
      font-family: $font-family-secondary;
      font-size: $font-size-xxl;
      font-weight: 500;
      letter-spacing: 0.1rem;
      margin-bottom: $gap-lg;
    }

    a:link {
      text-decoration: none;
      outline: none;
    }

    a {
      width: 100%;

      button {
        @include mix-button-basic;
        height: 40px;
        width: 100%;
        justify-content: flex-start;
      }

      &.inactive button {
        color: var(--color-primary);
        background: none;
        font-weight: 400;

        &:hover {
          background: var(--background-tertiary);
        }
      }

      &:focus {
        &.active button {
          background: var(--green-secondary);
        }

        &.inactive button {
          background: var(--background-tertiary);
        }
      }
    }
  }

  .sidebar__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $gap-md;
  }

  @media (min-width: $breakpoint-md) {
    left: 0;
    pointer-events: all;
  }
}
