@keyframes invalid-shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translate(-5px);
  }
  20% {
    transform: translate(-15px);
  }
  40% {
    transform: translate(15px);
  }
  60% {
    transform: translate(-15px);
  }
  80% {
    transform: translate(15px);
  }
  90% {
    transform: translate(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@mixin mix-pop-enter {
  transform: scale(0.1) rotateX(90deg);
}

@mixin mix-pop-enter-active {
  transform: scale(1.1) rotateX(-15deg);
}

@mixin mix-pop-enter-done {
  transform: scale(1) rotateX(0deg);
}
