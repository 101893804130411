@import 'styles/global';
@import 'styles/fonts';

body {
  margin: 0;
  font-family: $font-family-primary, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-primary);
  color: var(--color-primary);
}

code {
  font-family: $font-family-code, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  outline: 0;
  cursor: pointer;
}

// themes
body.light {
  @include light-theme();
}

body.dark {
  @include dark-theme();
}
