@import 'styles/global';

#App {
  height: 100%;

  .outlet__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    --outlet-padding: #{$gap-xl};
    --outlet-width: 1000px;

    .outlet {
      max-width: calc(var(--outlet-width) + #{$sidebar-width} + var(--outlet-padding) * 2);
      padding: $gap-xl var(--outlet-padding) $gap-xl;
      transition: padding $transition-timing $transition-duration;

      @media (min-width: $breakpoint-md) {
        padding: ($gap-xl * 2) var(--outlet-padding) ($gap-xl * 2)
          calc(#{$sidebar-width} + var(--outlet-padding));
      }
    }

    @media (min-width: $breakpoint-xl) {
      --outlet-padding: #{$gap-xl * 2};
    }
  }
}
