@import '../styles/global';

.tile-enter {
  @include mix-pop-enter;
}

.tile-enter-active {
  @include mix-pop-enter-active;
}

.tile-enter-done {
  @include mix-pop-enter-done;
}

.board {
  display: grid;
  gap: $gap-sm;
  grid-template-columns: repeat(5, 1fr);
  width: fit-content;

  $tile-size: 60px;

  .board__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 500;
    font-family: $font-family-secondary;
    text-transform: capitalize;
    width: $tile-size;
    height: $tile-size;
    border: 3px solid var(--background-tertiary);
    border-radius: $border-radius;
    transition: transform $transition-duration * 2 $transition-timing;

    &.wrong,
    &.partial,
    &.correct {
      color: var-dark('color-primary');
    }

    &.wrong {
      background: var-dark('background-tertiary');
      border-color: var-dark('background-tertiary');
    }

    &.partial {
      background: var(--cyan-primary);
      border-color: var(--cyan-primary);
    }

    &.correct {
      background: var(--green-primary);
      border-color: var(--green-primary);
    }
  }
}
